<template>
 <div v-if="session">
    <v-card outlined flat>
      <v-app-bar color="primary" short dense dark flat>
        <v-toolbar-title>Riwayat Pasangan</v-toolbar-title>
        <v-spacer></v-spacer>

        <div
          class="mr-3 mt-1"
          v-if="
            session.authorities.some((item) => item === 'CRUD_ADMINISTRATOR')
          "
        >
          <rwpasanganModalAdd />
        </div>

        <div style="max-width: 300px">
          <v-tabs v-model="tab" right dark background-color="primary">
            <v-tab href="#tab-1" @click="getSIMASN()">
              <h5>IDASN</h5>
            </v-tab>

            <v-tab href="#tab-2" @click="getSAPK()">
              <h5>BKN</h5>
            </v-tab>
          </v-tabs>
        </div>
      </v-app-bar>

      <v-tabs-items v-model="tab" touchless>
        <v-tab-item value="tab-1" class="pa-5">
          <rwpasanganSIMASN />
        </v-tab-item>
        <v-tab-item value="tab-2" class="pa-5">
          <rwpasanganSAPK />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";

import rwpasanganSIMASN from "@/components/asn/profil/rwpasangan/rwpasangan";
import rwpasanganSAPK from "@/components/asn/profil/rwpasangan/SAPK_rwpasangan_Modal";
import rwpasanganModalAdd from "@/components/asn/profil/rwpasangan/ModalAdd";

import getData from "@/store/asn/rwpasangan/tabs";

export default {
  name: "DataUtama",

  components: {
    rwpasanganSIMASN,
    rwpasanganSAPK,
    rwpasanganModalAdd,
  },

  computed: {
    dataSAPK: {
      get() {
        return getData.state.tabSAPK;
      },

      set(value) {
        getData.commit("getSAPK", value);
      },
    },
  },

  data: () => ({
    tab: null,
    session: '',
  }),

  mounted() {
    this.session = JSON.parse(Cookie.dec(Cookie.get("myCookie")));
  },

  methods: {
    getSAPK() {
      getData.commit("getSAPK", true);
      getData.commit("getSIMASN", false);
    },
    getSIMASN() {
      getData.commit("getSIMASN", true);
      getData.commit("getSAPK", false);
    },
  },
};
</script>
